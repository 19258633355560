import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Link } from '@entur/typography';
import { Checkbox, Fieldset } from '@entur/form';
import { ImportStatement } from '~/components/ImportStatement';
import { Playground } from '~/components/playground/Playground';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import doPic from './CheckboxDo.png';
import dontPic from './CheckboxDont.png';
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Fieldset label=\"Hvilke sparkesykler vil du se i kartet?\">\n  <Checkbox>Tier</Checkbox>\n  <Checkbox>Voi</Checkbox>\n  <Checkbox>Zvipp</Checkbox>\n</Fieldset>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Checkbox,
      Fieldset,
      ImportStatement,
      Playground,
      DoDontGroup,
      DoDontCard,
      doPic,
      dontPic,
      PageHeader,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      justifyContent: 'center'
    }} mdxType="Playground">
  <Fieldset label="Hvilke sparkesykler vil du se i kartet?" mdxType="Fieldset">
    <Checkbox mdxType="Checkbox">Tier</Checkbox>
    <Checkbox mdxType="Checkbox">Voi</Checkbox>
    <Checkbox mdxType="Checkbox">Zvipp</Checkbox>
  </Fieldset>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h3>
    <ImportStatement imports="Checkbox" mdxType="ImportStatement" />
    <Props of={Checkbox} mdxType="Props" />
    <h3 {...{
      "id": "fieldset"
    }}>{`Fieldset`}</h3>
    <ImportStatement imports="Fieldset" mdxType="ImportStatement" />
    <Props of={Fieldset} mdxType="Props" />
    <p><inlineCode parentName="p">{`Fieldset`}</inlineCode>{` lar deg gruppere flere `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`-er sammmen under en overskrift. Du kan sette en ledetekst/overskrift med `}<inlineCode parentName="p">{`label`}</inlineCode>{`-propen.`}</p>
    <p>{`Les mer om hvordan du bruker html-taggen `}<inlineCode parentName="p">{`fieldset`}</inlineCode>{` på sidene til `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset"
      }}>{`mdn web docs`}</a>{`;`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Brukes i tilfeller der det er en liste over alternativer, og brukeren kan velge ett eller flere
alternativer. En checkbox skal være venstrejustert i skjemaer. Den skal alltid stå foran
tilhørende label tekst, og bør aldri stå alene.`}</p>
    <p>{`Hvis brukerne bare kan velge ett alternativ fra listen, er det mer passende å bruke Radioknapper.
Om du ønsker at valget blir gjenspeilet eller lagret med en gang, bruk heller en Switch istedenfor.`}</p>
    <h3 {...{
      "id": "indeterminate"
    }}>{`Indeterminate`}</h3>
    <p>{`Det finnes tilfeller der en checkbox hverken er på eller av. Det er typisk når man bruker en checkbox til å velge en rekke andre checkboxes.`}</p>
    <p>{`Her har du et eksempel hvor det brukes. Prøv å velg ingen, alle eller en blanding.`}</p>
    <Playground __position={4} __code={'() => {\n  const [selectedItems, setSelectedItems] = React.useState({\n    Buss: false,\n    Tog: false,\n    Båt: false,\n    Fly: false,\n    Sykkel: false,\n    Sparkesykkel: false,\n  })\n  const isEverythingSelected = Object.values(selectedItems).every(\n    value => value,\n  )\n  const isNothingSelected = Object.values(selectedItems).every(\n    value => !value,\n  )\n  const isSomeSelected = !isEverythingSelected && !isNothingSelected\n  const handleAllOrNothingChange = () => {\n    setSelectedItems(prev =>\n      Object.keys(prev).reduce(\n        (acc, key) => ({ ...acc, [key]: !isEverythingSelected }),\n        {},\n      ),\n    )\n  }\n  const handleRegularChange = e => {\n    setSelectedItems({\n      ...selectedItems,\n      [e.target.name]: e.target.checked,\n    })\n  }\n  return (\n    <Fieldset label=\"Jeg vil gjerne reise med\">\n      <Checkbox\n        name=\"all\"\n        checked={isSomeSelected ? \'indeterminate\' : isEverythingSelected}\n        onChange={handleAllOrNothingChange}\n      >\n        Alt\n      </Checkbox>\n      {Object.entries(selectedItems).map(([name, checked]) => (\n        <Checkbox\n          key={name}\n          checked={checked}\n          name={name}\n          onChange={handleRegularChange}\n        >\n          {name}\n        </Checkbox>\n      ))}\n    </Fieldset>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Checkbox,
      Fieldset,
      ImportStatement,
      Playground,
      DoDontGroup,
      DoDontCard,
      doPic,
      dontPic,
      PageHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [selectedItems, setSelectedItems] = React.useState({
          Buss: false,
          Tog: false,
          Båt: false,
          Fly: false,
          Sykkel: false,
          Sparkesykkel: false
        });
        const isEverythingSelected = Object.values(selectedItems).every(value => value);
        const isNothingSelected = Object.values(selectedItems).every(value => !value);
        const isSomeSelected = !isEverythingSelected && !isNothingSelected;

        const handleAllOrNothingChange = () => {
          setSelectedItems(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc,
            [key]: !isEverythingSelected
          }), {}));
        };

        const handleRegularChange = e => {
          setSelectedItems({ ...selectedItems,
            [e.target.name]: e.target.checked
          });
        };

        return <Fieldset label="Jeg vil gjerne reise med" mdxType="Fieldset">
        <Checkbox name="all" checked={isSomeSelected ? 'indeterminate' : isEverythingSelected} onChange={handleAllOrNothingChange} mdxType="Checkbox">
          Alt
        </Checkbox>
        {Object.entries(selectedItems).map(([name, checked]) => <Checkbox key={name} checked={checked} name={name} onChange={handleRegularChange} mdxType="Checkbox">
            {name}
          </Checkbox>)}
      </Fieldset>;
      }}
    </Playground>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={doPic} mdxType="DoDontCard">
    Dersom det er mange checkboxes, bør du liste dem under hverandre i en
    kolonnegruppe slikt at det gjør det enkelt for brukeren å skanne raskt
    gjennom listen.
  </DoDontCard>
  <DoDontCard variant="negative" src={dontPic} mdxType="DoDontCard">
    Du bør unngå å plassere checkboxes i flere kolonner.
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      